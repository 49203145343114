import { graphql, Link } from 'gatsby';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Accordion from '../../components/ui/Accordion/Accordion';
import Avatar from '../../components/ui/Avatar/Avatar';
import Block from '../../components/ui/Cards/Block';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './contact.scss';
import contactImg from '../../components/ui/img/contactImg.jpg';


interface ContactProps {
    data: {
        authorsYaml: {
            authors: Author[];
        };
        file: FluidImage;
        faqYaml: {
            faq: QuestionAnswer[];
        };
    };
}
const Contact: React.FC<ContactProps> = ({ data }: ContactProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Contact"
                url="contact/"
                description="Comtact Page"
            />
            <PageSection>
    <Container>
        <div className='contactContainer'>
            <Group size={2}>
                <Block
                    className="contact-block"
                    direction={Direction.vertical}
                >
                    <h2>Contact Us</h2>

                    <p>
                        Thank you for your interest in ODO HOLDINGS LIMITED. Please feel free to reach out to us with any inquiries or feedback.
                    </p>

                    <p>
                        You can contact us via the following methods:
                    </p>

                    <ul>
                        <li>Email: odoholdingslimited@gmail.com</li>
                        <li>Phone: +254 713081707/ +254 729541616</li>
                        <li>Address: Nairobi City, Kenya</li>
                    </ul>
                </Block>
                <Block
                    className="contact-block"
                    direction={Direction.vertical}
                >
                    <img src={contactImg} />
                </Block>
            </Group>
        </div>
    </Container>
</PageSection>

        </PageLayout>
    );
};

export const contactQuery = graphql`
    {
        authorsYaml {
            authors {
                name
                linkedin
                twitter
                website
                image {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
                facebook
                github
                short_description
                url
            }
        }
        file(relativePath: { eq: "logo_placeholder.png" }) {
            childImageSharp {
                fluid {
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }

        faqYaml {
            faq {
                question
                answer
            }
        }
    }
`;

export default Contact;
